<template>
  <div class="full-height pa-10" style="margin-left: 56px">
    <div v-if="loading">
      <loader />
    </div>

    <v-snackbar v-model="snackbar" :timeout="snackBarTimeout" :color="color">{{
      snackBarText
    }}</v-snackbar>

    <div class="mx-auto rounded-0 full-height">
      <!-- TMA Content -->
      <v-sheet class="pa-4" color="grey darken-3" data-app>
        <v-row class="align-center justify-center">
          <v-col>
            <v-text-field
              class="pa-2 my-custom-search"
              v-model="search"
              label="Search Loan Applications..."
              flat
              solo-inverted
              hide-details
              clearable
              clear-icon="mdi-close-circle-outline"
            ></v-text-field>
          </v-col>
          <v-col cols="auto">
            <v-btn large outlined @click="openDialog">Add Loan</v-btn>
          </v-col>
        </v-row>
      </v-sheet>
      <div>
        <v-data-table
          :headers="loanHeaders"
          :items="los"
          :items-per-page="50"
          item-key="id"
          :search="search"
          sort-by="createdAt"
          :sort-desc="true"
          class="elevation-1"
        >
          <template v-slot:item.created_at="{ item }">
            <div v-if="item.created_at">
              {{
                item.created_at
                  .toDate()
                  .toLocaleString("en-US", {
                    year: "2-digit",
                    month: "numeric",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    hour12: true,
                  })
                  .replace(/\//g, "-")
              }}
            </div>
          </template>
          <template v-slot:item.loan_status="{ item }">
            <div v-if="item.loan_Status">TBD</div>
          </template>
          <template v-slot:item.is_process_application="{ item }">
            <div>
              {{
                item.is_process_application
                  ? item.is_process_application
                  : false
              }}
            </div>
          </template>
          <template v-slot:item.actions="{ item }">
            <router-link :to="`/los/loans/${item.id}`">
              <v-btn x-small outlined color="green accent-2">
                View Details
              </v-btn>
            </router-link>
          </template>
        </v-data-table>
      </div>
    </div>

    \
  </div>
</template>

<script>
import axios from "axios";
import Loader from "../../../components/Loader.vue";
import { firebaseappDB, firebaseDB } from "../../../auth/firebase-auth";

export default {
  template: "#app-template",
  name: "loans",
  components: {
    Loader,
  },
  data() {
    return {
      loanData: [],
      applicationData: {},
      dialog: false,
      notifications: false,
      sound: true,
      widgets: false,
      loading: true,
      color: "success",
      snackbar: false,
      snackBarText: "",
      tab: "",
      snackBarTimeout: 2000,
      search: "",
      los: [],
      valid: false,
      user: null,
      formData: {
        address: "",
        purchase_price: null,
        rehab_budget: null,
        investor_arv: null,
        credit_score: null,
        renovation_projects: null,
        property_condition: null,
        platform: "mobile",
        source: "app",
      },
      creditScoreOptions: ["Under 700", "700-741", "Over 741"],
      fixAndFlipOptions: [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11+",
      ],
      propertyConditionOptions: [
        "Uninhabitable",
        "Significant Repairs",
        "Worn Adequate",
        "Well Maintained",
        "Excellent",
        "Nearly New",
      ],
      autocompleteOptions: {
        types: ["address"],
        componentRestrictions: { country: "us" },
      },
      loanHeaders: [
        {
          text: "Email",
          align: "start",
          width: "100",
          value: "email",
        },
        // {
        //   text: "Reference #",
        //   align: "start",
        //   width: "50",
        //   value: "ReferenceNumber",
        // },
        {
          text: "Address",
          align: "start",
          width: "100",
          value: "street",
        },
        {
          text: "City",
          align: "start",
          width: "50",
          value: "city",
        },
        {
          text: "State",
          align: "start",
          width: "50",
          value: "state",
        },
        // {
        //   text: "Zipcode",
        //   align: "start",
        //   width: "50",
        //   value: "P1005",
        // },
        {
          text: "Created At",
          align: "start",
          width: "100",
          value: "created_at",
        },
        {
          text: "Processed",
          align: "center",
          width: "100",
          value: "is_process_application",
        },
        {
          text: "Actions",
          align: "center",
          width: "100",
          value: "actions",
        },
      ],
    };
  },
  mounted() {
    this.loading = true;
    let start = Date.now();
    console.log("Started : ", start);
    this.user = this.$cookies.get("user");
    console.log(this.user);
    this.fetchLoanData();
  },
  methods: {
    async fetchLoanData() {
      const losRef = firebaseappDB
        .firestore()
        .collection("projects")
        .orderBy("created_at", "desc")
        .where("source", "==", "app");
      try {
        const { docs } = await losRef.get();
        this.los = docs.map((doc) => {
          const { id } = doc;
          const data = doc.data();
          return { id, ...data };
        });
        this.loading = false;
      } catch (error) {
        console.log("Error in fetching los data:\n" + error);
        this.loading = false;
      }
    },
    openDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
    resetForm() {
      this.formData = {
        address: "",
        purchase_price: null,
        rehab_budget: null,
        investor_arv: null,
      };
    },
  },
};
</script>

<style scoped>
.my-custom-search {
  width: 15 00px;
  font-size: 16px;
}

.autocomplete-address {
  width: 100%;
  height: 50px;
  border: 2px solid #1976d2;
  padding: 0 16px;
  border-radius: 4px;
  color: white;
  margin-bottom: 20px;
}
</style>
