<template>
  <div class="full-height pa-10" style="margin-left: 56px">
    <div v-if="loading">
      <Loader :progress="progress" />
    </div>
    <div v-if="loadingBar">
      <ProgressBar :progress="progress" />
    </div>

    <v-snackbar v-model="snackbar" :color="snackbarColor" top
      >{{ snackBarText }}
      <v-btn color="white" class="ml-2" text @click="snackbar = false">
        Close
      </v-btn></v-snackbar
    >

    <div class="mx-auto rounded-0 full-height">
      <div>
        <!-- Loan Header -->
        <v-sheet :elevation="5" dark class="pa-5 mb-10">
          <div class="d-flex">
            <!-- Left Column - Loan Info -->
            <div class="d-flex flex-column">
              <div class="text-h5 font-weight-bold mb-2">
                {{
                  this.losObject?.loanProjectTitle
                    ? this.losObject?.loanProjectTitle
                    : `${this.losObject?.street || ""}, ${
                        this.losObject?.city || ""
                      }, ${this.losObject?.state || ""}, ${
                        this.losObject?.zip || ""
                      }`
                }}
              </div>

              <v-chip
                small
                class="mr-2"
                color="grey darken-1"
                style="width: 230px; text-align: center"
                outlined
              >
                Loan ID: {{ this.id }}
              </v-chip>
            </div>

            <!-- Right Column -->
            <v-spacer />

            <div class="d-flex flex-column align-end">
              <!-- Top Row - Buttons -->
              <div class="d-flex mb-3">
                <v-btn
                  class="mr-5"
                  color="white"
                  small
                  outlined
                  @click="dialog = true"
                >
                  View Logs
                </v-btn>

                <v-btn
                  class="mr-5"
                  color="white"
                  small
                  outlined
                  @click="openDialog"
                >
                  Analyze with AI
                </v-btn>

                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="white"
                      small
                      outlined
                      v-bind="attrs"
                      v-on="on"
                    >
                      Automations
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item>
                      <v-btn
                        v-if="source !== 'ma'"
                        color="white"
                        small
                        outlined
                        @click="processApplication"
                      >
                        Process Application
                      </v-btn>
                      <v-btn
                        v-else
                        color="white"
                        small
                        outlined
                        @click="dialoan = true"
                      >
                        Process Application
                      </v-btn>
                    </v-list-item>

                    <v-list-item>
                      <v-btn
                        color="white"
                        small
                        outlined
                        @click="generateReport()"
                      >
                        Download Pre Approval Letter
                      </v-btn>
                    </v-list-item>

                    <v-list-item v-if="!this.loading && this.losObject">
                      <v-btn
                        color="white"
                        small
                        outlined
                        @click="generateLoanReport()"
                      >
                        Download Term Sheet
                      </v-btn>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
              <template>
                <v-dialog v-model="dialoan" width="auto">
                  <v-card width="600">
                    <v-card-title class="text-h6">
                      <v-checkbox
                        v-model="sendData"
                        label="Send data to the Mortgage Automator"
                      ></v-checkbox>

                      <v-spacer />

                      <div class="c-pointer d-flex" @click="dialoan = false">
                        <v-icon>mdi-close</v-icon>
                      </div>
                    </v-card-title>

                    <v-card-actions>
                      <v-btn
                        class="btn-item custom-bg-secondary"
                        dark
                        small
                        block
                        @click="processApplication"
                        >Confirm Process</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </template>
              <!-- Bottom Row - Last Updated -->
              <div class="d-flex align-center grey--text text--lighten-1">
                <v-icon small class="mr-2">mdi-refresh</v-icon>
                <!-- <span class="text-caption"
                  >Last Updated:
                  {{
                    (this?.losObject?.updated_at)
                      .toDate()
                      .toLocaleString("en-US", {
                        year: "2-digit",
                        month: "numeric",
                        day: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                        hour12: true,
                      })
                      .replace(/\//g, "-")
                  }}</span
                > -->
              </div>
            </div>
          </div>

          <!-- Dialogs remain unchanged -->
          <v-dialog v-model="dialog" width="auto">
            <v-card width="600">
              <v-card-title class="text-h6">
                Loan Application Logs

                <v-spacer />

                <div class="c-pointer d-flex" @click="dialog = false">
                  <v-icon>mdi-close</v-icon>
                </div>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text class="mt-5">
                <template>
                  <div class="mb-2">
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-card class="pa-4" outlined>
                          <div class="text-subtitle-1 mb-2">
                            Processing Time
                          </div>
                          <div class="d-flex align-center">
                            <div>
                              {{ executionTimeMinutes }} minutes
                              {{ executionTimeSeconds }} seconds
                            </div>
                          </div>
                        </v-card>
                      </v-col>

                      <v-col cols="12" md="6">
                        <v-card class="pa-4" outlined>
                          <div class="text-subtitle-1 mb-2">
                            Processing Cost
                          </div>
                          <div class="d-flex align-center">
                            <div>
                              {{
                                formatCurrencyProcessing(totalProcessingCost)
                              }}
                              <div class="caption grey--text">
                                (ATTOM API:
                                {{
                                  formatCurrencyProcessing(
                                    attomApiCallCountValue
                                  )
                                }}, OpenAI:
                                {{
                                  formatCurrencyProcessing(
                                    losObject?.openAiCost || 0
                                  )
                                }})
                              </div>
                            </div>
                          </div>
                        </v-card>
                      </v-col>
                    </v-row>
                  </div>
                  <v-timeline density="compact" side="end" align="start">
                    <v-timeline-item
                      v-for="logItem of this.losLogObject"
                      :key="logItem.id"
                      :color="logItem.status == 'Success' ? 'blue' : 'red'"
                      small
                    >
                      <span class="text-gray-700 font-bold align-self-end"
                        ><strong>{{ logItem.title }}</strong> |
                        {{ logItem.time }}</span
                      >
                      <div class="mt-2 text-caption">
                        {{ logItem.description }}
                      </div>
                    </v-timeline-item>
                  </v-timeline>
                </template>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  class="btn-item custom-bg-secondary"
                  dark
                  small
                  block
                  @click="dialog = false"
                  >Close Logs</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="aiDialog" width="auto">
            <v-card width="600">
              <v-card-title class="text-h6">
                Analyze with AI

                <v-spacer />

                <div class="c-pointer d-flex" @click="aiDialog = false">
                  <v-icon>mdi-close</v-icon>
                </div>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <v-container>
                  <v-row v-for="(item, index) in parsedContent" :key="index">
                    <v-col v-if="item.type === 'title'" cols="12">
                      <h3 v-html="item.content"></h3>
                    </v-col>
                    <v-col v-else-if="item.type === 'subtitle'" cols="12">
                      <h4 v-html="item.content"></h4>
                    </v-col>
                    <v-col v-else-if="item.type === 'bullet'" cols="12">
                      <p v-html="'- ' + item.content"></p>
                    </v-col>
                    <v-col v-else-if="item.type === 'numbered'" cols="12">
                      <p v-html="item.content"></p>
                    </v-col>
                    <v-col v-else-if="item.type === 'text'" cols="12">
                      <p v-html="item.content"></p>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  class="btn-item custom-bg-secondary"
                  dark
                  small
                  block
                  @click="aiDialog = false"
                >
                  Close
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-sheet>

        <!-- Loan Overview Section -->
        <v-row class="mb-0">
          <v-col lg="12">
            <v-card color="grey darken-3">
              <v-card-text>
                <v-row>
                  <!-- Left Column -->
                  <v-col cols="12" md="6" class="d-flex flex-column">
                    <!-- Borrower Info Card -->
                    <v-card
                      class="grey darken-2 pa-4 flex-grow-1 mb-6"
                      outlined
                    >
                      <div class="text-subtitle-1 mb-4">
                        Borrower Information
                      </div>
                      <v-list-item dense class="px-0">
                        <v-list-item-icon class="mr-2">
                          <v-icon small>mdi-account</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content class="text-left">
                          <v-list-item-title
                            class="text-h6 light-blue--text text--lighten-2"
                          >
                            {{
                              this.source == "ma"
                                ? this?.TmaBorrowerData?.first_name
                                : this.losObject?.first_name
                            }}
                            {{
                              this.source == "ma"
                                ? this?.TmaBorrowerData?.last_name
                                : this.losObject?.last_name
                            }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item dense class="px-0">
                        <v-list-item-icon class="mr-2">
                          <v-icon small>mdi-email</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content class="text-left">
                          <v-list-item-title
                            class="light-blue--text text--lighten-2"
                          >
                            {{
                              this.source == "ma"
                                ? this?.TmaBorrowerData?.email
                                : this.losObject?.email
                            }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-card>

                    <!-- Property Details Card -->
                    <v-card class="grey darken-2 pa-4 flex-grow-1" outlined>
                      <div class="text-subtitle-1 mb-4">Property Details</div>
                      <v-list-item dense class="px-0">
                        <v-list-item-icon class="mr-2">
                          <v-icon small>mdi-map-marker</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content class="text-left">
                          <v-list-item-title>
                            {{ this.losObject?.street }},
                            {{ this.losObject?.city }},
                            {{ this.losObject?.state }},
                            {{ this.losObject?.zip }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item dense class="px-0">
                        <v-list-item-icon class="mr-2">
                          <v-icon small>mdi-home</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content class="text-left">
                          <v-list-item-title>
                            {{ totalBedrooms }} bed, {{ totalBathrooms }} bath
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item dense class="px-0">
                        <v-list-item-icon class="mr-2">
                          <v-icon small>mdi-ruler-square</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content class="text-left">
                          <v-list-item-title>
                            Structure: {{ propertySize }} sqft | Lot:
                            {{ Lot }} sqft
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-card>
                  </v-col>

                  <!-- Right Column -->
                  <v-col cols="12" md="6" class="d-flex flex-column">
                    <!-- Financial Details Card -->
                    <v-card class="pa-4 mb-6 flex-grow-1" outlined>
                      <div class="text-subtitle-1 mb-3">Financial Details</div>

                      <div class="mb-3">
                        <div class="caption text-grey">Purchase Price</div>
                        <div class="text-h6 orange--text">
                          {{ formatCurrency(purchasePrice) }}
                        </div>
                      </div>

                      <div class="mb-3">
                        <div class="caption text-grey">Rehab Budget</div>
                        <div class="text-h6 orange--text">
                          {{ formatCurrency(rehabBudget) }}
                        </div>
                      </div>

                      <div>
                        <div class="caption text-grey">ARV</div>
                        <div class="text-h6 orange--text">
                          {{ formatCurrency(ARV) }}
                        </div>
                      </div>
                    </v-card>
                    <v-row>
                      <!-- Crebrid APO -->
                      <v-col cols="3">
                        <v-card
                          class="pa-3 d-flex flex-column justify-space-between fill-height"
                          outlined
                        >
                          <div class="text-subtitle-2">Crebrid APO</div>
                          <div class="text-h6 green--text text--accent-2">
                            {{ formatCurrency(this.losObject?.c_apo) }}
                          </div>
                          <div class="caption mt-1">
                            <v-icon
                              x-small
                              :color="
                                this.losObject?.c_apo_wo_xpmultiplier >= ARV
                                  ? 'green'
                                  : 'red'
                              "
                            >
                              {{
                                this.losObject?.c_apo_wo_xpmultiplier >= ARV
                                  ? "mdi-arrow-up"
                                  : "mdi-arrow-down"
                              }}
                            </v-icon>
                            {{
                              formatCurrency(
                                Math.abs(
                                  this.losObject?.c_apo_wo_xpmultiplier - ARV
                                )
                              )
                            }}
                            ({{
                              calculatePercentDiff(
                                this.losObject?.c_apo_wo_xpmultiplier,
                                ARV
                              )
                            }}%) vs ARV
                          </div>
                          <div class="caption green--text text--accent-2">
                            NP: {{ this.losObject?.c_apo_np }}/50
                          </div>
                        </v-card>
                      </v-col>

                      <!-- APO V2 -->
                      <v-col cols="3">
                        <v-card class="pa-3 fill-height" outlined>
                          <div class="text-subtitle-2">APO V2</div>
                          <div
                            class="text-body-2 green--text text--accent-2 mb-1"
                          >
                            APO:
                            {{
                              formatCurrency(
                                this.losObject.c_apo_v2?.apo_v2_final_value
                              )
                            }}
                          </div>
                          <div
                            class="text-body-2 green--text text--accent-2 mb-1"
                          >
                            APO Low:
                            {{
                              formatCurrency(
                                this.losObject.c_apo_v2?.apo_v2_final_low_value
                              )
                            }}
                          </div>
                          <div class="text-body-2 green--text text--accent-2">
                            APO High:
                            {{
                              formatCurrency(
                                this.losObject.c_apo_v2?.apo_v2_final_high_value
                              )
                            }}
                          </div>
                        </v-card>
                      </v-col>

                      <!-- APO V3 -->
                      <v-col cols="3">
                        <v-card class="pa-3 fill-height" outlined>
                          <div class="text-subtitle-2">APO V3</div>
                          <div
                            class="text-body-2 green--text text--accent-2 mb-1"
                          >
                            Balanced APO:
                            {{
                              formatCurrency(
                                this.losObject.c_apo_v3?.balanced_apo
                              )
                            }}
                          </div>
                          <div class="text-body-2 green--text text--accent-2">
                            Conservative APO:
                            {{
                              formatCurrency(
                                this.losObject.c_apo_v3?.conservative_apo
                              )
                            }}
                          </div>
                        </v-card>
                      </v-col>

                      <!-- APO V4 -->
                      <v-col cols="3">
                        <v-card class="pa-3 fill-height" outlined>
                          <div class="text-subtitle-2">APO V4</div>
                          <div
                            class="text-body-2 green--text text--accent-2 mb-1"
                          >
                            Balanced APO:
                            {{
                              formatCurrency(
                                this.losObject.c_apo_v4?.balanced_apo
                              )
                            }}
                          </div>
                          <div class="text-body-2 green--text text--accent-2">
                            Conservative APO:
                            {{
                              formatCurrency(
                                this.losObject.c_apo_v4?.conservative_apo
                              )
                            }}
                          </div>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>

      <!-- Map Section -->
      <v-sheet :elevation="5" dark class="pa-5 mt-10">
        <v-card color="grey darken-3">
          <v-tabs
            v-model="mapTab"
            color="green accent-2"
            slider-color="green accent-2"
            @change="onTabChange"
          >
            <v-tab>Map</v-tab>
            <v-tab>Street View</v-tab>
          </v-tabs>

          <v-tabs-items v-model="mapTab">
            <v-tab-item>
              <div class="pa-5">
                <div
                  v-if="
                    this.losObject &&
                    this.propertyAddress &&
                    this.attomDataObject?.closest_properties_full_data
                  "
                >
                  <MapComponent
                    :loan="this.losObject"
                    :subjectProperty="this.subjectProperty"
                    :closestPropertyObj="
                      this.attomDataObject.closest_properties_full_data
                    "
                    :zoomLevel="17"
                  />
                </div>
              </div>
            </v-tab-item>

            <v-tab-item>
              <div v-if="losObject && losObject.street" class="pa-5">
                <v-sheet :elevation="5" dark>
                  <div id="pano"></div>
                  <div id="map"></div>
                </v-sheet>
              </div>
              <div
                v-else-if="
                  !this.loading && this?.losObject?.is_process_application
                "
                class="mt-15 pb--1 green--text pa-5"
              >
                Subject Property Address is empty.
              </div>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-sheet>

      <!-- Expansion Panel Section -->
      <v-sheet :elevation="5" dark class="pa-5 mt-10">
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <div class="text-subtitle-1 white--text">Deal Data</div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-expansion-panels>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <div class="text-subtitle-1 white--text">
                      Composite Score
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div v-if="loanApproval">
                      <div class="mt-3">
                        <div class="pb-2"><b>Scoring Output</b></div>

                        <v-row>
                          <v-col lg="2">
                            <div class="orange--text mb-1">
                              Credit Score Result:
                              <ul>
                                <li>
                                  {{
                                    this.loanPreApprovalData?.loan_approval?.results.credit_score_result.toFixed(
                                      2
                                    )
                                  }}
                                </li>

                                <li>
                                  {{ this.losObject?.creditScore }}
                                </li>
                              </ul>
                            </div>
                          </v-col>

                          <v-col lg="2">
                            <div class="orange--text mb-1">
                              Experience Result:
                              <ul>
                                <li>
                                  {{
                                    this.loanPreApprovalData?.loan_approval?.results?.experience_result.toFixed(
                                      2
                                    )
                                  }}
                                </li>
                                <li>
                                  {{ this.losObject?.renovationProjects }}
                                </li>
                              </ul>
                            </div>
                          </v-col>

                          <v-col lg="2">
                            <div class="orange--text mb-1">
                              Liquidity Result:
                              <ul>
                                <li>
                                  {{
                                    this.loanPreApprovalData?.loan_approval?.results?.liquidity_result.toFixed(
                                      2
                                    )
                                  }}
                                </li>
                                <li>
                                  {{ this.losObject?.liquidityAssets }}
                                </li>
                              </ul>
                            </div>
                          </v-col>

                          <v-col lg="2">
                            <div class="orange--text mb-1">
                              Loan-to-value Result:
                              <ul>
                                <li>
                                  {{
                                    this.loanPreApprovalData?.loan_approval?.results?.loan_to_value_result.toFixed(
                                      2
                                    )
                                  }}
                                </li>
                                <li>
                                  {{ this.loanToValue }}
                                </li>
                              </ul>
                            </div>
                          </v-col>

                          <v-col lg="2">
                            <div class="orange--text mb-1">
                              Loan-to-cost Result:
                              <ul>
                                <li>
                                  {{
                                    this.loanPreApprovalData?.loan_approval?.results?.ltc_result.toFixed(
                                      2
                                    )
                                  }}
                                </li>
                                <li>
                                  {{ this.loanToCost }}
                                </li>
                              </ul>
                            </div>
                          </v-col>
                        </v-row>
                      </div>

                      <div class="mt-5">
                        <div><b>Scoring Rules</b></div>
                        Our 5-stage pre-approval system is based on years of
                        experience in the lending industry. Credit score,
                        liquidity, loan-to-value ratio, deal experience, and
                        loan-to-cost ratio all contribute a maximum score of
                        five points each, for a total possible application score
                        of 25 points. A minimum score of 15/25 is required to
                        advance a loan application. In addition, certain
                        disqualifying factors may nullify otherwise acceptable
                        applications:

                        <ul>
                          <li>Credit score must be at least 580</li>
                          <li>
                            Liquidity must be at least ${{
                              format$Currency(
                                this.losObject["bankStatementAmount"]
                              )
                            }}
                          </li>
                          <li>
                            Loan-to-cost must be less than or equal to 90%
                          </li>
                          <li>
                            Loan-to-value must be less than or equal to 70%
                          </li>
                        </ul>
                        <br />

                        <div><b>Scoring Composite</b></div>
                        <v-row>
                          <v-col lg="2">
                            Credit Score:
                            <ul>
                              <li>600-650: 2</li>
                              <li>650-700: 3</li>
                              <li>700-750: 4</li>
                              <li>750+: 5</li>
                            </ul>
                          </v-col>

                          <v-col lg="2">
                            Experience:
                            <ul>
                              <li>0: 1.5</li>
                              <li>1: 2.5</li>
                              <li>2: 3.5</li>
                              <li>3: 4.5</li>
                              <li>4+: 5</li>
                            </ul>
                          </v-col>

                          <v-col lg="2">
                            Liquidity:
                            <ul>
                              <li>$50,000: 1</li>
                              <li>$65,000: 2</li>
                              <li>$90,000: 3</li>
                              <li>$125,000: 4</li>
                              <li>$185,000+: 5</li>
                            </ul>
                          </v-col>

                          <v-col lg="2">
                            LTV:
                            <ul>
                              <li>70%: 3</li>
                              <li>65%: 4</li>
                              <li>&lt;52%: 5</li>
                            </ul>
                          </v-col>

                          <v-col lg="2">
                            LTC:
                            <ul>
                              <li>90%: 3</li>
                              <li>87%: 4</li>
                              <li>&lt;80%: 5</li>
                            </ul>
                          </v-col>
                        </v-row>
                      </div>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <div class="text-subtitle-1 white--text">Formula Data</div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div>
                      <v-row>
                        <v-col cols="12" md="12">
                          <v-card class="pa-4 mb-4" outlined>
                            <div class="text-subtitle-1 mb-2">
                              Price Per Square Foot
                            </div>
                            <div class="d-flex align-center py-1 px-4">
                              <div>Purchase Price PSF:</div>
                              <v-spacer></v-spacer>
                              <div>
                                {{
                                  formatCurrency(
                                    this.losObject?.purchase_price_psf ?? 0
                                  )
                                }}
                              </div>
                            </div>
                            <div
                              class="d-flex align-center py-1 px-4 grey darken-3"
                            >
                              <div>Rehab Budget PSF:</div>
                              <v-spacer></v-spacer>
                              <div>
                                {{
                                  formatKCurrency(
                                    this.losObject?.rehab_budget_psf ?? 0
                                  )
                                }}
                              </div>
                            </div>
                            <div class="d-flex align-center py-1 px-4">
                              <div>Investor ARV PSF:</div>
                              <v-spacer></v-spacer>
                              <div>
                                {{
                                  formatKCurrency(
                                    this.losObject?.investor_arv_psf ?? 0
                                  )
                                }}
                              </div>
                            </div>
                          </v-card>
                        </v-col>

                        <v-col cols="12" md="12">
                          <v-card class="pa-4 mb-4" outlined>
                            <div class="text-subtitle-1 mb-2">ATTOM Data</div>
                            <div class="d-flex align-center py-1 px-4">
                              <div>ATTOM AVM PSF:</div>
                              <v-spacer></v-spacer>
                              <div>
                                {{
                                  formatKCurrency(
                                    this.losObject?.attom_avm_psf ?? 0
                                  )
                                }}
                              </div>
                            </div>
                            <div
                              class="d-flex align-center py-1 px-4 grey darken-3"
                            >
                              <div>ATTOM AVM:</div>
                              <v-spacer></v-spacer>
                              <div>{{ formatCurrency(this.avmAmount) }}</div>
                            </div>
                            <div
                              v-if="avmProperty"
                              class="d-flex align-center py-1 px-4"
                            >
                              <div>ATTOM AVM Value Range:</div>
                              <v-spacer></v-spacer>
                              <div>
                                ({{ formatKCurrency(this.avmAmountHigh) }} -
                                {{ formatKCurrency(this.avmAmountLow) }})
                              </div>
                            </div>
                            <div
                              class="d-flex align-center py-1 px-4 grey darken-3"
                            >
                              <div>ATTOM Assessment Value:</div>
                              <v-spacer></v-spacer>
                              <div>
                                {{ formatCurrency(this.assessmentValue) }}
                              </div>
                            </div>
                            <div class="d-flex align-center py-1 px-4">
                              <div>ATTOM Assessment Psf:</div>
                              <v-spacer></v-spacer>
                              <div>
                                {{ formatCurrency(this.assessmentValuePSF) }}
                              </div>
                            </div>
                          </v-card>
                        </v-col>

                        <v-col cols="12" md="12">
                          <v-card class="pa-4 mb-4" outlined>
                            <div class="text-subtitle-1 mb-2">APO Results</div>
                            <div class="d-flex align-center py-1 px-4">
                              <div>
                                A1 = (AVM/sqft Average of Top 3 * Subject
                                Property Sqft):
                              </div>
                              <v-spacer></v-spacer>
                              <div>
                                {{
                                  formatKCurrency(
                                    losObject?.proxsey_v2_result?.A?.A1 ?? 0
                                  )
                                }}
                              </div>
                            </div>
                            <div
                              class="d-flex align-center py-1 px-4 grey darken-3"
                            >
                              <div>
                                A2 = (AVM/sqft Median of the 10 * Subject
                                Property Sqft):
                              </div>
                              <v-spacer></v-spacer>
                              <div>
                                {{
                                  formatKCurrency(
                                    losObject?.proxsey_v2_result?.A?.A2 ?? 0
                                  )
                                }}
                              </div>
                            </div>
                            <div class="d-flex align-center py-1 px-4">
                              <div>
                                A3 = (AVM/sqft Average of Bottom 3 * Subject
                                Property Sqft):
                              </div>
                              <v-spacer></v-spacer>
                              <div>
                                {{
                                  formatKCurrency(
                                    losObject?.proxsey_v2_result?.A?.A3 ?? 0
                                  )
                                }}
                              </div>
                            </div>
                            <div
                              class="d-flex align-center py-1 px-4 grey darken-3"
                            >
                              <div>
                                B1 = (AVM/sqft Average of Top 3 * Subject
                                Property Sqft):
                              </div>
                              <v-spacer></v-spacer>
                              <div>
                                {{
                                  formatKCurrency(
                                    losObject?.proxsey_v2_result?.B?.B1 ?? 0
                                  )
                                }}
                              </div>
                            </div>
                            <div class="d-flex align-center py-1 px-4">
                              <div>
                                B2 = (AVM/sqft Median of the 20 * Subject
                                Property Sqft):
                              </div>
                              <v-spacer></v-spacer>
                              <div>
                                {{
                                  formatKCurrency(
                                    losObject?.proxsey_v2_result?.B?.B2 ?? 0
                                  )
                                }}
                              </div>
                            </div>
                            <div
                              class="d-flex align-center py-1 px-4 grey darken-3"
                            >
                              <div>
                                B3 = (AVM/sqft Average of Bottom 3 * Subject
                                Property Sqft):
                              </div>
                              <v-spacer></v-spacer>
                              <div>
                                {{
                                  formatKCurrency(
                                    losObject?.proxsey_v2_result?.B?.B3 ?? 0
                                  )
                                }}
                              </div>
                            </div>
                            <div class="d-flex align-center py-1 px-4">
                              <div>
                                C1 = (AVM/sqft Average of Top 3 * Subject
                                Property Sqft):
                              </div>
                              <v-spacer></v-spacer>
                              <div>
                                {{
                                  formatKCurrency(
                                    losObject?.proxsey_v2_result?.C?.C1 ?? 0
                                  )
                                }}
                              </div>
                            </div>
                            <div
                              class="d-flex align-center py-1 px-4 grey darken-3"
                            >
                              <div>
                                C2 = (AVM/sqft Median of the 30 * Subject
                                Property Sqft):
                              </div>
                              <v-spacer></v-spacer>
                              <div>
                                {{
                                  formatKCurrency(
                                    losObject?.proxsey_v2_result?.C?.C2 ?? 0
                                  )
                                }}
                              </div>
                            </div>
                            <div class="d-flex align-center py-1 px-4">
                              <div>
                                C3 = (AVM/sqft Average of Bottom 3 * Subject
                                Property Sqft):
                              </div>
                              <v-spacer></v-spacer>
                              <div>
                                {{
                                  formatKCurrency(
                                    losObject?.proxsey_v2_result?.C?.C3 ?? 0
                                  )
                                }}
                              </div>
                            </div>
                            <div
                              class="d-flex align-center py-1 px-4 grey darken-3"
                            >
                              <div>
                                APO (Sellable ARV): ((A1*3) + (B1*2.5) + (C1*2))
                                / 7.5:
                              </div>
                              <v-spacer></v-spacer>
                              <div>
                                {{
                                  formatKCurrency(
                                    losObject?.c_apo_wo_xpmultiplier ?? 0
                                  )
                                }}
                              </div>
                            </div>
                            <div class="d-flex align-center py-1 px-4">
                              <div>
                                Emergency Exit: ((A2*3) + (B2*2.5) + (C2*2)) /
                                7.5:
                              </div>
                              <v-spacer></v-spacer>
                              <div>
                                {{
                                  formatKCurrency(
                                    losObject?.emergency_exit ?? 0
                                  )
                                }}
                              </div>
                            </div>
                            <div
                              class="d-flex align-center py-1 px-4 grey darken-3"
                            >
                              <div>
                                Optimal Buy Price: (Estimated ARV*.7)-Rehab
                                Budget:
                              </div>
                              <v-spacer></v-spacer>
                              <div>
                                {{
                                  formatKCurrency(
                                    losObject?.optimal_buy_price ?? 0
                                  )
                                }}
                              </div>
                            </div>
                            <div class="d-flex align-center py-1 px-4">
                              <div>APO / Sqft</div>
                              <v-spacer></v-spacer>
                              <div>
                                {{ formatKCurrency(losObject?.apo_sqft ?? 0) }}
                              </div>
                            </div>
                            <div
                              class="d-flex align-center py-1 px-4 grey darken-3"
                            >
                              <div>
                                NP (Number of Closest Properties with Higher
                                AVM/Sqft than APO)
                              </div>
                              <v-spacer></v-spacer>
                              <div>{{ losObject?.c_apo_np ?? 0 }}/50</div>
                            </div>
                          </v-card>
                        </v-col>

                        <v-col cols="12" md="12">
                          <v-card class="pa-4 mb-4" outlined>
                            <div class="text-subtitle-1 mb-2">
                              APO v2 Results
                            </div>
                            <div class="d-flex align-center py-1 px-4">
                              <div>
                                Average of the Top 10 Highest Assessed Value :
                              </div>
                              <v-spacer></v-spacer>
                              <div>
                                {{
                                  formatKCurrency(
                                    losObject?.average_of_top_10 ?? 0
                                  )
                                }}
                              </div>
                            </div>
                            <div
                              class="d-flex align-center py-1 px-4 grey darken-3"
                            >
                              <div>
                                The Highest Assessed Value Per Square Foot:
                              </div>
                              <v-spacer></v-spacer>
                              <div>
                                {{
                                  formatKCurrency(
                                    losObject?.highest_assessment_sqft ?? 0
                                  )
                                }}
                              </div>
                            </div>
                            <div class="d-flex align-center py-1 px-4">
                              <div>
                                Price Per Square Foot : (Average of the Top 10
                                Highest Assessed Value * 2 + The Highest
                                Assessed Value per Square Foot * 3) / 5
                              </div>
                              <v-spacer></v-spacer>
                              <div>
                                {{
                                  formatKCurrency(
                                    losObject?.crebrid_apo_2_price_per_sqft ?? 0
                                  )
                                }}
                              </div>
                            </div>
                            <div
                              class="d-flex align-center py-1 px-4 grey darken-3"
                            >
                              <div>
                                APO v2: (Price Per Square Foot * Subject
                                Property Square Foot)
                              </div>
                              <v-spacer></v-spacer>
                              <div>
                                {{ formatKCurrency(losObject?.c_apo_2 ?? 0) }}
                              </div>
                            </div>
                            <div class="d-flex align-center py-1 px-4">
                              <div>APOv2 / Sqft</div>
                              <v-spacer></v-spacer>
                              <div>
                                {{
                                  formatKCurrency(
                                    losObject?.crebrid_apo_2_price_per_sqft ?? 0
                                  )
                                }}
                              </div>
                            </div>
                            <div
                              class="d-flex align-center py-1 px-4 grey darken-3"
                            >
                              <div>
                                NP (Number of Closest Properties with Higher
                                Assess/Sqft than APOv2)
                              </div>
                              <v-spacer></v-spacer>
                              <div>{{ losObject?.c_apo_2_np ?? 0 }}/50</div>
                            </div>
                          </v-card>
                        </v-col>
                        <v-col cols="12" md="12">
                          <v-card class="pa-4 mb-4" outlined>
                            <div class="text-subtitle-1 mb-2">REO Results</div>
                            <div class="d-flex align-center py-1 px-4">
                              <div>REO Value : (APO * 0.91)</div>
                              <v-spacer></v-spacer>
                              <div>
                                {{ formatKCurrency(losObject?.c_reo ?? 0) }}
                              </div>
                            </div>
                            <div
                              class="d-flex align-center py-1 px-4 grey darken-3"
                            >
                              <div>REO / Sqft</div>
                              <v-spacer></v-spacer>
                              <div>
                                {{ formatKCurrency(losObject?.reo_sqft ?? 0) }}
                              </div>
                            </div>
                            <div class="d-flex align-center py-1 px-4">
                              <div>
                                NP (Number of Closest Properties with Higher
                                AVM/Sqft than REO)
                              </div>
                              <v-spacer></v-spacer>
                              <div>{{ losObject?.c_reo_np ?? 0 }}/50</div>
                            </div>
                          </v-card>
                        </v-col>
                      </v-row>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-sheet>

      <!-- JSON Data Section -->
      <v-sheet :elevation="5" dark class="pa-5 mt-10">
        <v-card color="grey darken-3">
          <v-tabs
            v-model="jsonTab"
            color="green accent-2"
            slider-color="green accent-2"
          >
            <v-tab>Loan Data</v-tab>
            <v-tab>Subject Property Data</v-tab>
            <v-tab>Neighborhood Data</v-tab>
            <v-tab>50 Closest Properties (Min)</v-tab>
            <v-tab>50 Closest Properties (Full)</v-tab>
            <v-tab>Community Data</v-tab>
            <v-tab>Sales Trend</v-tab>
            <v-tab>APO V2</v-tab>
            <v-tab>APO V3</v-tab>
            <v-tab>APO V4</v-tab>
          </v-tabs>

          <v-tabs-items v-model="jsonTab">
            <!-- New Loan Data Tab Item -->
            <v-tab-item>
              <div class="pa-5">
                <div
                  v-if="!this.loading && this.losObject"
                  class="code-details"
                >
                  <MonacoEditor
                    v-model="formattedLosObject"
                    width="100%"
                    height="600"
                    theme="vs-dark"
                    language="json"
                    :options="options"
                  ></MonacoEditor>
                </div>
                <div v-else-if="!this.loading" class="mt-15 green--text">
                  Loan data is empty.
                </div>
              </div>
            </v-tab-item>

            <!-- Subject Property AVM Details Tab -->
            <v-tab-item>
              <div class="pa-5">
                <div
                  v-if="!this.loading && this.subjectPropertyAVMDetail"
                  class="code-details"
                >
                  <MonacoEditor
                    v-model="this.subjectPropertyAVMDetail"
                    width="100%"
                    height="600"
                    theme="vs-dark"
                    language="json"
                    :options="options"
                  ></MonacoEditor>
                </div>
                <div v-else-if="!this.loading" class="mt-15 green--text">
                  Subject Property AVM Details data is empty.
                </div>
              </div>
            </v-tab-item>

            <!-- AVM Details Tab -->
            <v-tab-item>
              <div class="pa-5">
                <div
                  v-if="!this.loading && this.attomAvmDetail"
                  class="code-details"
                >
                  <MonacoEditor
                    v-model="this.attomAvmDetail"
                    width="100%"
                    height="600"
                    theme="vs-dark"
                    language="json"
                    :options="options"
                  ></MonacoEditor>
                </div>
                <div v-else-if="!this.loading" class="mt-15 green--text">
                  AVM Details data is empty.
                </div>
              </div>
            </v-tab-item>

            <!-- 50 Closest Properties Min Data Tab -->
            <v-tab-item>
              <div class="pa-5">
                <div
                  v-if="!this.loading && this.closestPropertiesMinData"
                  class="code-details"
                >
                  <MonacoEditor
                    v-model="this.closestPropertiesMinData"
                    width="100%"
                    height="600"
                    theme="vs-dark"
                    language="json"
                    :options="options"
                  ></MonacoEditor>
                </div>
                <div v-else-if="!this.loading" class="mt-15 green--text">
                  50 Closest AVM Properties JSON Min Data is empty.
                </div>
              </div>
            </v-tab-item>

            <!-- 50 Closest Properties Full Data Tab -->
            <v-tab-item>
              <div class="pa-5">
                <div
                  v-if="!this.loading && this.closestPropertiesFullData"
                  class="code-details"
                >
                  <MonacoEditor
                    v-model="this.closestPropertiesFullData"
                    width="100%"
                    height="600"
                    theme="vs-dark"
                    language="json"
                    :options="options"
                  ></MonacoEditor>
                </div>
                <div v-else-if="!this.loading" class="mt-15 green--text">
                  50 Closest AVM Properties JSON Full Data is empty.
                </div>
              </div>
            </v-tab-item>

            <!-- Community Data Tab -->
            <v-tab-item>
              <div class="pa-5">
                <div
                  v-if="!this.loading && this.communitydata"
                  class="code-details"
                >
                  <MonacoEditor
                    v-model="this.communitydata"
                    width="100%"
                    height="600"
                    theme="vs-dark"
                    language="json"
                    :options="options"
                  ></MonacoEditor>
                </div>
                <div v-else class="mt-15 green--text">
                  Community data is empty.
                </div>
              </div>
            </v-tab-item>

            <!-- Sales Trend Data Tab -->
            <v-tab-item>
              <div class="pa-5">
                <div
                  v-if="!this.loading && this.salestrenddata"
                  class="code-details"
                >
                  <MonacoEditor
                    v-model="this.salestrenddata"
                    width="100%"
                    height="600"
                    theme="vs-dark"
                    language="json"
                    :options="options"
                  ></MonacoEditor>
                </div>
                <div v-else class="mt-15 green--text">
                  Sales Trend data is empty.
                </div>
              </div>
            </v-tab-item>
            <!-- APO v2 Data -->
            <v-tab-item>
              <div class="pa-5">
                <div v-if="!this.loading && this.apov2" class="code-details">
                  <MonacoEditor
                    v-model="this.apov2"
                    width="100%"
                    height="600"
                    theme="vs-dark"
                    language="json"
                    :options="options"
                  ></MonacoEditor>
                </div>
                <div v-else class="mt-15 green--text">
                  APO V2 data is empty.
                </div>

                <v-btn
                  class="mt-5"
                  color="white"
                  small
                  outlined
                  @click="modifierDialog = true"
                >
                  APO Formula
                </v-btn>
              </div>
            </v-tab-item>
            <!-- APO v3 Data -->
            <v-tab-item>
              <div class="pa-5">
                <div v-if="!this.loading && this.apov3" class="code-details">
                  <MonacoEditor
                    v-model="this.apov3"
                    width="100%"
                    height="600"
                    theme="vs-dark"
                    language="json"
                    :options="options"
                  ></MonacoEditor>
                </div>
                <div v-else class="mt-15 green--text">
                  APO V3 data is empty.
                </div>

                <v-btn
                  class="mt-5"
                  color="white"
                  small
                  outlined
                  @click="modifierV3Dialog = true"
                >
                  APO Formula
                </v-btn>
              </div>
            </v-tab-item>
            <!-- APO v4 Data -->
            <v-tab-item>
              <div class="pa-5">
                <div v-if="!this.loading && this.apov4" class="code-details">
                  <MonacoEditor
                    v-model="this.apov4"
                    width="100%"
                    height="600"
                    theme="vs-dark"
                    language="json"
                    :options="options"
                  ></MonacoEditor>
                </div>
                <div v-else class="mt-15 green--text">
                  APO V4 data is empty.
                </div>

                <v-btn
                  class="mt-5"
                  color="white"
                  small
                  outlined
                  @click="modifierV4Dialog = true"
                >
                  APO Formula
                </v-btn>
              </div>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
        <v-dialog v-model="modifierDialog" width="auto">
          <v-card width="800">
            <v-card-title class="text-h6">
              APO v2
              <v-spacer />

              <div class="c-pointer d-flex" @click="modifierDialog = false">
                <v-icon>mdi-close</v-icon>
              </div>
            </v-card-title>

            <v-divider></v-divider>

            <v-card-text>
              <v-container>
                <!-- Rehab Budget & Property Condition -->
                <v-row>
                  <v-col cols="12">
                    <h3>Rehab Budget & Property Condition</h3>
                    <v-data-table
                      :headers="rehabHeaders"
                      :items="rehabData"
                      disable-pagination
                      hide-default-footer
                      :item-class="
                        (item) =>
                          highlightRow(
                            item,
                            'apo_v2_modifier_1_percentage',
                            'apo_v2'
                          )
                      "
                      class="elevation-1"
                      dense
                    />
                  </v-col>
                </v-row>

                <!-- Credit Score -->
                <v-row>
                  <v-col cols="12">
                    <h3>Credit Score</h3>
                    <v-data-table
                      :headers="creditScoreHeaders"
                      :items="creditScoreData"
                      disable-pagination
                      hide-default-footer
                      :item-class="
                        (item) =>
                          highlightRow(
                            item,
                            'apo_v2_modifier_2_percentage',
                            'apo_v2'
                          )
                      "
                      class="elevation-1"
                      dense
                    />
                  </v-col>
                </v-row>

                <!-- Fix-and-Flip Experience -->
                <v-row>
                  <v-col cols="12">
                    <h3>Fix-and-Flip Experience</h3>
                    <v-data-table
                      :headers="flipHeaders"
                      :items="flipData"
                      disable-pagination
                      hide-default-footer
                      :item-class="
                        (item) =>
                          highlightRow(
                            item,
                            'apo_v2_modifier_3_percentage',
                            'apo_v2'
                          )
                      "
                      class="elevation-1"
                      dense
                    />
                  </v-col>
                </v-row>

                <!-- Loan-to-Value (LTV) Ratio -->
                <v-row>
                  <v-col cols="12">
                    <h3>Loan-to-Value (LTV) Ratio</h3>
                    <v-data-table
                      :headers="ltvHeaders"
                      :items="ltvData"
                      disable-pagination
                      hide-default-footer
                      :item-class="
                        (item) =>
                          highlightRow(
                            item,
                            'apo_v2_modifier_4_percentage',
                            'apo_v2'
                          )
                      "
                      class="elevation-1"
                      dense
                    />
                  </v-col>
                </v-row>

                <!-- Loan-to-Cost (LTC) Ratio -->
                <v-row>
                  <v-col cols="12">
                    <h3>Loan-to-Cost (LTC) Ratio</h3>
                    <v-data-table
                      :headers="ltcHeaders"
                      :items="ltcData"
                      :item-class="
                        (item) =>
                          highlightRow(
                            item,
                            'apo_v2_modifier_5_percentage',
                            'apo_v2'
                          )
                      "
                      disable-pagination
                      hide-default-footer
                      class="elevation-1"
                      dense
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-btn
                class="btn-item custom-bg-secondary"
                dark
                small
                block
                @click="modifierDialog = false"
              >
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="modifierV3Dialog" width="auto">
          <v-card width="800">
            <v-card-title class="text-h6">
              APO v3 - Formulas
              <v-spacer />
              <div class="c-pointer d-flex" @click="modifierV3Dialog = false">
                <v-icon>mdi-close</v-icon>
              </div>
            </v-card-title>

            <v-divider></v-divider>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <h3>Square Footage</h3>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <h3>Purchase Price</h3>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <h3>Rehab Budget</h3>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <h3>Total Investment</h3>
                    <v-card-text> Purchase Price + Rehab Budget </v-card-text>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <h3>Median Top 20 PPSF</h3>
                    <v-card-text>
                      Median Top 20 PPSF = Middle Value of Sorted PPSF of Top 20
                    </v-card-text>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12">
                    <h3>Median PPSF for All 50</h3>
                    <v-card-text>
                      Median PPSF for All 50 = Middle Value of Sorted PPSF of
                      All 50
                    </v-card-text>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12">
                    <h3>Median Home Size</h3>
                    <v-card-text
                      >Median Home Size = Middle Value of Sorted Home
                      Sizes</v-card-text
                    >
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12">
                    <h3>Deviation Ratio</h3>
                    <v-card-text
                      >(Square Footage - Median Home Size) / Median Home
                      Size</v-card-text
                    >
                  </v-col>
                </v-row>

                <!-- High-End (Balanced APO) Section -->
                <v-row>
                  <v-col cols="12">
                    <h3>For High-End (Balanced APO):</h3>
                    <v-list dense>
                      <v-list-item>
                        <v-list-item-content>
                          <v-row align="center" no-gutters>
                            <v-col class="font-weight-bold text-left" cols="6"
                              >Adjustment Factor:</v-col
                            >
                            <v-col cols="6">1 + (0.15 × Deviation Ratio)</v-col>
                          </v-row>
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item>
                        <v-list-item-content>
                          <v-row align="center" no-gutters>
                            <v-col class="font-weight-bold text-left" cols="6"
                              >Adjusted PPSF (High-End):</v-col
                            >
                            <v-col cols="6"
                              >Median Top 20 PPSF × Adjustment Factor</v-col
                            >
                          </v-row>
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item>
                        <v-list-item-content>
                          <v-row align="center" no-gutters>
                            <v-col class="font-weight-bold text-left" cols="6"
                              >Balanced APO:</v-col
                            >
                            <v-col cols="6"
                              >Adjusted PPSF (High-End) × Square Footage</v-col
                            >
                          </v-row>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-col>
                </v-row>

                <!-- Conservative APO Section -->
                <v-row>
                  <v-col cols="12">
                    <h3>For Conservative (Conservative APO):</h3>
                    <v-list dense>
                      <v-list-item>
                        <v-list-item-content>
                          <v-row align="center" no-gutters>
                            <v-col class="font-weight-bold text-left" cols="6"
                              >Adjusted PPSF (Conservative):</v-col
                            >
                            <v-col cols="6"
                              >Median PPSF for All 50 × Adjustment Factor</v-col
                            >
                          </v-row>
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item>
                        <v-list-item-content>
                          <v-row align="center" no-gutters>
                            <v-col class="font-weight-bold text-left" cols="6"
                              >Conservative APO:</v-col
                            >
                            <v-col cols="6"
                              >Adjusted PPSF (Conservative) × Square
                              Footage</v-col
                            >
                          </v-row>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-col>
                </v-row>

                <!-- Additional Calculations -->
                <v-row>
                  <v-col cols="12">
                    <h3>Purchase Price per Sqft</h3>
                    <v-card-text>Purchase Price / Square Footage</v-card-text>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12">
                    <h3>Total Investment per Sqft</h3>
                    <v-card-text>Total Investment / Square Footage</v-card-text>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-btn
                class="btn-item custom-bg-secondary"
                dark
                small
                block
                @click="modifierDialog = false"
              >
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="modifierV4Dialog" width="auto">
          <v-card width="800">
            <v-card-title class="text-h6">
              APO v4
              <v-spacer />

              <div class="c-pointer d-flex" @click="modifierDialog = false">
                <v-icon>mdi-close</v-icon>
              </div>
            </v-card-title>

            <v-divider></v-divider>

            <v-card-text>
              <v-container>
                <!-- Rehab Budget & Property Condition -->
                <v-row>
                  <v-col cols="12">
                    <h3>Rehab Budget & Property Condition</h3>
                    <v-data-table
                      :headers="rehabHeaders"
                      :items="rehabData"
                      disable-pagination
                      hide-default-footer
                      :item-class="
                        (item) =>
                          highlightRow(
                            item,
                            'apo_v4_modifier_1_percentage',
                            'apo_v4'
                          )
                      "
                      class="elevation-1"
                      dense
                    />
                  </v-col>
                </v-row>

                <!-- Credit Score -->
                <v-row>
                  <v-col cols="12">
                    <h3>Credit Score</h3>
                    <v-data-table
                      :headers="creditScoreHeaders"
                      :items="creditScoreData"
                      disable-pagination
                      hide-default-footer
                      :item-class="
                        (item) =>
                          highlightRow(
                            item,
                            'apo_v4_modifier_2_percentage',
                            'apo_v4'
                          )
                      "
                      class="elevation-1"
                      dense
                    />
                  </v-col>
                </v-row>

                <!-- Fix-and-Flip Experience -->
                <v-row>
                  <v-col cols="12">
                    <h3>Fix-and-Flip Experience</h3>
                    <v-data-table
                      :headers="flipHeaders"
                      :items="flipData"
                      disable-pagination
                      hide-default-footer
                      :item-class="
                        (item) =>
                          highlightRow(
                            item,
                            'apo_v4_modifier_3_percentage',
                            'apo_v4'
                          )
                      "
                      class="elevation-1"
                      dense
                    />
                  </v-col>
                </v-row>

                <!-- Loan-to-Value (LTV) Ratio -->
                <v-row>
                  <v-col cols="12">
                    <h3>Loan-to-Value (LTV) Ratio</h3>
                    <v-data-table
                      :headers="ltvHeaders"
                      :items="ltvData"
                      disable-pagination
                      hide-default-footer
                      :item-class="
                        (item) =>
                          highlightRow(
                            item,
                            'apo_v4_modifier_4_percentage',
                            'apo_v4'
                          )
                      "
                      class="elevation-1"
                      dense
                    />
                  </v-col>
                </v-row>

                <!-- Loan-to-Cost (LTC) Ratio -->
                <v-row>
                  <v-col cols="12">
                    <h3>Loan-to-Cost (LTC) Ratio</h3>
                    <v-data-table
                      :headers="ltcHeaders"
                      :items="ltcData"
                      :item-class="
                        (item) =>
                          highlightRow(
                            item,
                            'apo_v4_modifier_5_percentage',
                            'apo_v4'
                          )
                      "
                      disable-pagination
                      hide-default-footer
                      class="elevation-1"
                      dense
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-btn
                class="btn-item custom-bg-secondary"
                dark
                small
                block
                @click="modifierV4Dialog = false"
              >
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-sheet>

      <template>
        <div class="d-flex justify-center align-center">
          <v-btn
            v-if="source !== 'ma'"
            class="mt-5 mr-10"
            color="white"
            small
            outlined
            @click="showDeleteConfirmation = true"
          >
            Delete Loan
          </v-btn>

          <v-dialog v-model="showDeleteConfirmation" max-width="400px">
            <v-card>
              <v-card-title class="headline"> Confirm Deletion </v-card-title>
              <v-card-text>
                Are you sure you want to delete this loan? This action cannot be
                undone.
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue-grey"
                  text
                  @click="showDeleteConfirmation = false"
                >
                  Cancel
                </v-btn>
                <v-btn color="red" text @click="deleteLoan"> Delete </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </template>
    </div>
  </div>
</template>

   
<script>
import axios from "axios";
import MonacoEditor from "monaco-editor-vue";
import { firebaseDB, firebaseappDB } from "../../../auth/firebase-auth";
import Loader from "../../../components/Loader.vue";
import ProgressBar from "../../../components/ProgressBar.vue";
import MapComponent from "../../../components/MapComponent.vue";

export default {
  name: "Los",
  components: {
    Loader,
    MonacoEditor,
    ProgressBar,
    MapComponent,
  },
  props: ["id"],
  data() {
    return {
      showDeleteConfirmation: false,
      modifierDialog: false,
      modifierV3Dialog: false,
      modifierV4Dialog: false,
      sendData: false,
      dialoan: false,
      progress: 0,
      apiDuration: 30000,
      progressSteps: 4,
      progressComplete: false,
      street_map: null,
      panorama: null,
      loading: true,
      loadingBar: false,
      dialog: false,
      aiDialog: false,
      parsedContent: [],
      color: "success",
      snackbar: false,
      snackBarText: "",
      snackbarColor: "",
      snackBarTimeout: 5000,
      disableDownload: false,
      options: {},
      avmProperty: "",
      subjectProperty: "",
      propertyAddress: "",
      keystoneAvg2: (this.avmAmount + this.ARV) / 2,
      keystoneAvg3: "",
      keystoneAvg: "",
      minComAvmAvg: 0,
      maxComAvmAvg: 0,
      minComAvmpsf: 0,
      maxComAvmpsf: 0,
      keystoneV3median: 0,
      keystoneV3highV2: 0,
      keystoneV3lowV2: 0,
      keystoneV3medianV2: 0,
      LE: "",
      borrowerDialog: false,
      propertyDialog: false,
      compsDialog: false,
      los: [],
      processLoanApplicationData: {},
      losObject: null,
      attomDataObject: null,
      salesComparablesParam: null,
      aiAnalyzeData: null,
      mile: 0,
      sqFeetRange: 0,
      bedroomsRange: 0,
      bathroomRange: 0,
      loanPreApprovalData: null,
      losLogObject: null,
      mappedLosObj: null,
      saleObj: null,
      propertyBasicProfile: "",
      attomAvmDetail: "",
      subjectPropertyAVMDetail: "",
      salesComparables: "",
      salesHistoryDetail: "",
      avgAVMCom: null,
      totalBedrooms: 0,
      totalBathrooms: 0,
      propertySize: 0,
      Lot: 0,
      avmAmount: 0,
      avmAmountLow: 0,
      avmAmountHigh: 0,
      avmPsfValue: 0,
      avmSnapShot: "",
      avmSnapShotDetails: "",
      avmDeatils: "",
      avmAssessmentSnapShot: "",
      avmAssessmentSnapShotString: "",
      confidence: 0,
      totalAcquisitionCost: 0,
      downPayment: 0,
      interestAmount: 0,
      loanPointsAmount: 0,
      realtorAmount: 0,
      propertyTaxes: 0,
      propertyInsurance: 0,
      titleInsuranceAmount: 0,
      acquisitionCostPsf: 0,
      arvPsfValue: 0,
      assessmentValue: 0,
      assessmentValuePSF: 0,
      profitPercentage: "",
      profitDollarAmount: "",
      loanAmount: "",
      totalCostBasis: "",
      purchasePrice: "$150,000",
      rehabBudget: "$25,000",
      closingCosts: "$2000",
      ARV: "$225,000",
      loanTermMonths: 0,
      interestRate: 0,
      loanPointsPercent: 0,
      bpoAppraisalValue: 0,
      titleInsurancePercent: 0,
      governmentFee: 0,
      warehouseInvestorFee: 0,
      realtorRate: 0,
      insuranceRate: 0,
      taxRate: 0,
      closingCostsRate: "0.04",
      utilities: 0,
      drawFees: 0,
      profitAmount: "",
      editMode: true,
      loanToCost: 0,
      loanToValue: 0,
      communitydata: null,
      salestrenddata: null,
      apov2: null,
      apov3: null,
      apov4: null,
      neighborhoodHEValue: 0,
      executionTimeValue: "",
      attomApiCallCountValue: 0,
      executionTimeMinutes: 0,
      executionTimeSeconds: 0.0,
      totalProcessingCost: 0,
      closestPropertiesFullData: null,
      closestPropertiesMinData: null,
      subjectPropertyLat: null,
      subjectPropertyLng: null,
      mapTab: null,
      jsonTab: null,
      compTab: null,
      source: "app",
      loanId: null,
      mortgageLoanId: null,
      TmaBorrowerData: null,
      rehabHeaders: [
        {
          text: "Rehab Budget per Sqft",
          value: "rehab",
          align: "start",
          width: "100",
        },
        { text: "APO Modifier", value: "apo", align: "start", width: "100" },
      ],
      rehabData: [
        { rehab: "<$10/sqft", apo: "-1%" },
        { rehab: "$10/sqft", apo: "+1%" },
        { rehab: "$20/sqft", apo: "+2%" },
        { rehab: "$30/sqft", apo: "+3%" },
        { rehab: "$40/sqft", apo: "+4%" },
        { rehab: "$50/sqft", apo: "+5%" },
        { rehab: ">$50/sqft", apo: "+6%" },
      ],

      creditScoreHeaders: [
        { text: "Credit Score", value: "score", align: "start", width: "100" },
        { text: "APO Modifier", value: "apo", align: "start", width: "100" },
      ],
      creditScoreData: [
        { score: "600", apo: "-2%" },
        { score: "650", apo: "-1%" },
        { score: "700", apo: "0%" },
        { score: "750", apo: "+1%" },
        { score: "800", apo: "+2%" },
        { score: "<700", apo: "-2%" },
        { score: "700-741", apo: "+1%" },
        { score: ">741", apo: "+2%" },
      ],

      flipHeaders: [
        {
          text: "Number of Flips",
          value: "flips",
          align: "start",
          width: "100",
        },
        { text: "APO Modifier", value: "apo", align: "start", width: "100" },
      ],
      flipData: [
        { flips: "0", apo: "-1%" },
        { flips: "1", apo: "0%" },
        { flips: "2", apo: "+1%" },
        { flips: "5", apo: "+2%" },
        { flips: "10+", apo: "+3%" },
      ],

      ltvHeaders: [
        { text: "LTV Ratio", value: "ltv", align: "start", width: "100" },
        { text: "APO Modifier", value: "apo", align: "start", width: "100" },
      ],
      ltvData: [
        { ltv: "< 60%", apo: "+2%" },
        { ltv: "60-70%", apo: "+1%" },
        { ltv: "70-80%", apo: "0%" },
        { ltv: "80-90%", apo: "-1%" },
        { ltv: "> 90%", apo: "-2%" },
      ],

      ltcHeaders: [
        { text: "LTC Ratio", value: "ltc", align: "start", width: "100" },
        { text: "APO Modifier", value: "apo", align: "start", width: "100" },
      ],
      ltcData: [
        { ltc: "< 60%", apo: "+2%" },
        { ltc: "60-70%", apo: "+1%" },
        { ltc: "70-80%", apo: "0%" },
        { ltc: "80-90%", apo: "-1%" },
        { ltc: "> 90%", apo: "-2%" },
      ],
    };
  },

  mounted() {
    this.loanId = this.$route.query.loan_id; // Access 'loan_id'
    this.source = this.$route.query.source || "app"; // Access 'source'
    console.log("Loan ID:", this.loanId); // Outputs: 67890
    console.log("Source:", this.source); // Outputs: ma

    this.fetchLosDetail();
  },

  computed: {
    // totalCostBasis: function () {
    //   return (
    //     // Number(this.propertyClosingCost) +
    //     (
    //       Number(this.propertyInsurance) +
    //       Number(this.propertyTaxes) +
    //       Number(this.utilities) +
    //       Number(this.drawFees) +
    //       Number(this.purchasePrice) +
    //       Number(this.rehabBudget) +
    //       Number(this.loanPointsAmount) +
    //       Number(this.titleInsuranceAmount) +
    //       Number(this.governmentFee) +
    //       Number(this.warehouseInvestorFee) +
    //       Number(this.interestAmount)
    //     ).toLocaleString("en-US")
    //   );
    // },

    loanApproval() {
      return this.loanPreApprovalData
        ? this.loanPreApprovalData?.loan_approval
        : null;
    },
    fundedLoansData() {
      return this.$store.state.fundedLoans;
    },
    inProgressData() {
      return this.$store.state.inProgressLoans;
    },
    formattedLosObject() {
      return JSON.stringify(this.losObject);
    },
  },

  methods: {
    highlightRow(item, key, type) {
      let data =
        type === "apo_v2" ? this.losObject?.c_apo_v2 : this.losObject?.c_apo_v4;
      if (!data) return "";

      let percentage = parseFloat(data[key]);
      let formattedPercentage = (parseFloat(item.apo) || 0) / 100;

      if (
        key === "apo_v4_modifier_2_percentage" ||
        key === "apo_v2_modifier_2_percentage"
      ) {
        console.log(item);
        console.log(this.losObject.credit_score);
        if (
          this.losObject.credit_score === "Under 700" ||
          this.losObject.credit_score === "700-741" ||
          this.losObject.credit_score === "Over 741"
        ) {
          if (
            (item.score === "<700" &&
              this.losObject.credit_score === "Under 700") ||
            (item.score === "700-741" &&
              this.losObject.credit_score === "700-741") ||
            (item.score === ">741" &&
              this.losObject.credit_score === "Over 741")
          ) {
            return formattedPercentage === percentage ? "custom-highlight" : "";
          }
        } else if (
          item.score !== "<700" &&
          item.score !== ">741" &&
          item.score !== "700-741"
        ) {
          return formattedPercentage === percentage ? "custom-highlight" : "";
        }
      } else {
        return formattedPercentage === percentage ? "custom-highlight" : "";
      }
    },
    async deleteLoan() {
      this.showDeleteConfirmation = false;
      this.loading = true;
      let db;
      if (this.source == "app") {
        db = firebaseappDB.firestore();
      }
      if (this.source == "web") {
        db = firebaseDB.firestore();
      }

      const loanId = this.$props.id;
      const docRef = db.collection("projects").doc(loanId);

      const subcollectionNames = [
        "attomData",
        "loanApproval",
        "loanOffers",
        "logs",
      ];

      const deleteCollection = async (collectionPath) => {
        const collectionRef = db.collection(collectionPath);
        const snapshot = await collectionRef.get();

        for (const doc of snapshot.docs) {
          for (const subcollection of subcollectionNames) {
            const subcollectionPath = `${collectionPath}/${doc.id}/${subcollection}`;
            await deleteCollection(subcollectionPath);
          }

          await doc.ref.delete();
        }
      };

      try {
        console.log("Deleting loan with ID:", loanId);

        for (const subcollection of subcollectionNames) {
          const subcollectionPath = `projects/${loanId}/${subcollection}`;
          await deleteCollection(subcollectionPath);
        }

        await docRef.delete();

        this.$router.push("/los/crebridapp");
        this.loading = false;
      } catch (error) {
        console.error("Error deleting loan:", error);
        alert("Failed to delete the loan. Please try again.");
      }
    },
    openDialog() {
      this.aiDialog = true;
      this.parseContent();
    },
    parseContent() {
      const rawContent = this.aiAnalyzeData.choices[0].message.content;
      this.parsedContent = this.convertToVueComponents(rawContent);
    },
    onTabChange(index) {
      if (index === 1) {
        this.streetViewMap(this.propertyAddress);
      }
    },
    convertToVueComponents(content) {
      const lines = content.split("\n");
      const components = [];

      lines.forEach((line) => {
        line = line.trim();

        if (!line) return;

        if (line.startsWith("### ")) {
          components.push({ type: "title", content: line.replace("### ", "") });
        } else if (line.startsWith("#### ")) {
          components.push({
            type: "subtitle",
            content: line.replace("#### ", ""),
          });
        } else if (line.startsWith("- ")) {
          components.push({
            type: "bullet",
            content: this.processBoldText(line.replace("- ", "")),
          });
        } else if (line.match(/^\d+\.\s/)) {
          components.push({
            type: "numbered",
            content: this.processBoldText(line),
          });
        } else if (line.includes("**")) {
          components.push({
            type: "text",
            content: this.processBoldText(line),
          });
        } else {
          components.push({ type: "text", content: line });
        }
      });

      return components;
    },

    processBoldText(line) {
      return line.replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>");
    },

    filterObjectByURL(obj) {
      return Object.fromEntries(
        Object.entries(obj).filter(
          ([key, value]) => value && value.trim() !== ""
        )
      );
    },

    async saveApplication() {
      try {
        const applicationRef = await firebaseDB
          .firestore()
          .collection("loans")
          .doc(this.id);

        await applicationRef.update(this.mappedLosObj);
        this.fetchLosDetail();
        console.log("Data updated in Firebase successfully!");
        this.editMode = true;
      } catch (error) {
        console.error("Error updating data in Firebase:", error);
      }
    },
    async streetViewMap(propertyAddress) {
      this.loading = true;

      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ address: propertyAddress }, (results, status) => {
        if (status === "OK") {
          const subLatLong = results[0].geometry.location;
          console.log("Subject property address subLatLong: " + subLatLong);

          const mapOptions = {
            center: subLatLong,
            zoom: 14,
          };

          console.log(document.getElementById("map"));

          this.street_map = new google.maps.Map(
            document.getElementById("map"),
            mapOptions
          );

          this.panorama = new google.maps.StreetViewPanorama(
            document.getElementById("pano"),
            {
              position: subLatLong,
              pov: { heading: 0, pitch: 0 },
              zoom: 0,
            }
          );

          this.street_map.setStreetView(this.panorama);
          this.loading = false;
        } else {
          console.error(
            "Geocode was not successful for the following reason: " + status
          );
        }
      });
    },

    async processApplication() {
      this.loading = true;
      this.dialoan = false;

      let url;
      if (this.source == "ma") {
        url =
          "https://us-central1-crebrid-os.cloudfunctions.net/crebridApp/api/v1/process-mortgage-loan";
        this.processLoanApplicationData["loan_id"] = this.$props.id;
        this.processLoanApplicationData["sendData"] = this.sendData;
      } else {
        url =
          "https://us-central1-crebrid-os.cloudfunctions.net/crebridApp/api/v1/process-loan";
        this.processLoanApplicationData["is_process_application"] = true;
        this.processLoanApplicationData["app_id"] = this.$props.id;
        this.processLoanApplicationData["platform"] = "mobile";
        this.processLoanApplicationData["propertyAddressLat"] =
          this.losObject?.property_address_lat;
        this.processLoanApplicationData["propertyAddressLong"] =
          this.losObject?.property_address_long;
      }
      const token = `Bearer eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJtZXNzYWdlIjoiVG9rZW4gZm9yIGNyZWJyaWQtb3MgQVBJIiwiaWF0IjoxNjc1MzQxOTA1fQ._MKnNlPgvURchmlE1YuewP_FEfJ7rJMmVnsiR0FyfPJ_1BQAdPojeOvfwTPaegyqMFYXQN21ytPMl24Zky589A`;

      await axios
        .post(`${url}`, this.processLoanApplicationData, {
          headers: { Authorization: token },
        })
        .then((response) => {
          this.loading = false;
          this.sendData = false;
          this.snackBarText = response.data.message;
          this.snackbarColor = "green";
          this.snackbar = true;
          this.fetchLosDetail();
        })
        .catch((err) => {
          this.loading = false;
          this.snackBarText = "Something went wrong, please check the logs!";
          this.snackbarColor = "red";
          this.snackbar = true;
          console.log("Error: ", err);
        });
    },

    async fetchLosDetail() {
      console.log("Starting fetchLosDetail...");
      this.loading = true;
      let losRef = null,
        losLogRef = null,
        mortgageLoanId,
        collectionRef,
        mortgageLoanQuerySnapshot;
      try {
        if (this.source == "ma") {
          mortgageLoanQuerySnapshot = await firebaseDB
            .firestore()
            .collection("loans")
            .where("loan_id", "==", Number(this.loanId))
            .get();

          if (!mortgageLoanQuerySnapshot.empty) {
            console.log(
              "Number of documents fetched:",
              mortgageLoanQuerySnapshot.docs.length
            );
            const mortgageLoanDocument = mortgageLoanQuerySnapshot.docs[0];
            const mortgageLoanData = mortgageLoanDocument.data();
            console.log("mortgageLoanData--", mortgageLoanData);

            this.losObject = { ...mortgageLoanData };
            this.mortgageLoanId = mortgageLoanDocument.id;
            collectionRef = firebaseDB
              .firestore()
              .collection("loans")
              .doc(this.mortgageLoanId);

            const tmaBorrowersSnapshot = await firebaseDB
              .firestore()
              .collection("loans")
              .doc(this.mortgageLoanId)
              .collection("tma_borrowers")
              .get();

            if (!tmaBorrowersSnapshot.empty) {
              const firstTmaBorrowerDocument = tmaBorrowersSnapshot.docs[0];
              this.TmaBorrowerData = firstTmaBorrowerDocument.data();
            } else {
              const mortgageLoanDoc = await firebaseDB
                .firestore()
                .collection("mortgageLoans")
                .doc(this.$props.id)
                .get();
              if (mortgageLoanDoc.exists) {
                const mortgageLoanDocData = mortgageLoanDoc.data();
                const subCollectionDoc = await firebaseDB
                  .firestore()
                  .collection("mortgageLoans")
                  .doc(this.$props.id)
                  .collection("mortgageAutomatorLoan")
                  .doc(mortgageLoanDocData.mortgageLoanId)
                  .get();

                if (subCollectionDoc.exists) {
                  let mortgageAutomatorLoanData = subCollectionDoc.data();
                  let borrowers = mortgageAutomatorLoanData.borrowers;
                  for (let i = 0; i < borrowers.length; i++) {
                    if (
                      borrowers[i]?.l_is_main_client == 1 &&
                      borrowers[i]?.l_is_guarantor == 0
                    ) {
                      this.TmaBorrowerData = borrowers[i];
                      break;
                    }
                  }
                }
              }
            }
          } else {
            this.loading = false;
          }
        } else if (this.source == "web") {
          console.log("in else----");
          losRef = await firebaseDB
            .firestore()
            .collection("projects")
            .doc(this.$props.id)
            .get();
          collectionRef = firebaseDB
            .firestore()
            .collection("projects")
            .doc(this.$props.id);

          this.losObject = { ...losRef.data() };
        } else {
          console.log("in else----");
          losRef = await firebaseappDB
            .firestore()
            .collection("projects")
            .doc(this.$props.id)
            .get();
          collectionRef = firebaseappDB
            .firestore()
            .collection("projects")
            .doc(this.$props.id);

          this.losObject = { ...losRef.data() };
        }
        if (
          this.source == "app" ||
          this.source == "web" ||
          (mortgageLoanQuerySnapshot &&
            !mortgageLoanQuerySnapshot.empty &&
            this.source == "ma")
        ) {
          let eventsResult = [];
          const logdata = await collectionRef.collection("logs").get();
          console.log("log data", logdata);
          logdata.docs.forEach((doc) => {
            eventsResult.push(doc.data());
          });

          this.executionTimeValue = this.losObject?.execution_time || 0;
          if (isNaN(this.executionTimeValue)) {
            this.executionTimeMinutes = 0;
            this.executionTimeSeconds = "0.00";
          } else {
            this.executionTimeMinutes = Math.floor(
              this.executionTimeValue / 60000
            );
            this.executionTimeSeconds = (
              (this.executionTimeValue % 60000) /
              1000
            ).toFixed(2);
          }

          // Calculate the total cost based on the number of ATTOM API calls
          const costPerCall = 0.2;
          this.attomApiCallCountValue =
            (this.losObject?.attom_api_call_count || 0) * costPerCall;

          this.totalProcessingCost =
            (this.losObject?.openAiCost || 0) + this.attomApiCallCountValue;

          // this.mappedLosObj = { ...losRef.data() };
          this.losLogObject = eventsResult;
          if (this.losObject?.platform && this.losObject.platform == "mobile") {
            let linkData = await firebaseappDB
              .firestore()
              .collection("loans")
              .doc(this.$props.id)
              .collection("documents")
              .doc("all_documents")
              .get();
            let rehabBudgetDoc = await firebaseappDB
              .firestore()
              .collection("loans")
              .doc(this.$props.id)
              .collection("RehabBudgets")
              .doc("rehab_budgets_data")
              .get();
            this.rehabBudgetData = { ...rehabBudgetDoc.data() };

            this.documents = { ...linkData.data() };

            this.businessDocument = this.documents.businessDocument
              ? this.filterObjectByURL(this.documents.businessDocument)
              : null;
            this.personalDocument = this.documents.personalDocument
              ? this.filterObjectByURL(this.documents.personalDocument)
              : null;
            this.propertyDocument = this.documents.propertyDocument
              ? this.filterObjectByURL(this.documents.propertyDocument)
              : null;
          }

          const attomDocdata = await collectionRef
            .collection("attomData")
            .doc(this.losObject.latest_attom_data_id)
            .get();
          this.attomDataObject = { ...attomDocdata.data() };

          const loanApprovalData = await collectionRef
            .collection("loanApproval")
            .doc(this.losObject.loan_pre_approval_id)
            .get();
          this.loanPreApprovalData = { ...loanApprovalData.data() };

          console.log("losObject", this.losObject);
          console.log("losLogObject", this.losLogObject);
          console.log("attom data object--", this.attomDataObject);
          console.log("loanPreApprovalData ---", this.loanPreApprovalData);
          if (this.losObject?.c_apo_v2) {
            this.apov2 = JSON.stringify(this.losObject.c_apo_v2);
          }
          if (this.losObject?.c_apo_v3) {
            this.apov3 = JSON.stringify(this.losObject.c_apo_v3);
          }
          if (this.losObject?.c_apo_v4) {
            this.apov4 = JSON.stringify(this.losObject.c_apo_v4);
          }
          if (this.attomDataObject?.neighborhood_community_data) {
            this.communitydata = JSON.stringify(
              this.attomDataObject.neighborhood_community_data
            );
          }
          if (this.attomDataObject.sales_trend_data) {
            this.salestrenddata = JSON.stringify(
              this.attomDataObject.sales_trend_data
            );
          }
          if (this.attomDataObject.attom_avm_detail) {
            this.attomAvmDetail = JSON.stringify(
              this.attomDataObject?.attom_avm_detail
            );
          }

          if (this.attomDataObject?.subject_property_avm_detail) {
            this.subjectPropertyAVMDetail = JSON.stringify(
              this.attomDataObject?.subject_property_avm_detail
            );
            let subject_property_avm_detail =
              this.attomDataObject?.subject_property_avm_detail?.property;

            this.subjectPropertyLat =
              subject_property_avm_detail[0]?.location?.latitude;
            console.log("this.subjectPropertyLat", this.subjectPropertyLat);
            this.subjectPropertyLng =
              subject_property_avm_detail[0]?.location?.longitude;
          }
          if (this.attomDataObject?.closest_properties_min_data) {
            this.closestPropertiesMinData = JSON.stringify(
              this.attomDataObject?.closest_properties_min_data
            );
          }
          if (this.attomDataObject?.closest_properties_full_data) {
            this.closestPropertiesFullData = JSON.stringify(
              this.attomDataObject?.closest_properties_full_data
            );
          }
          this.propertyAddress = `${this.losObject.street}, ${this.losObject.city}, ${this.losObject.state}, ${this.losObject.zip}`;
          this.subjectProperty = {
            Address: this.propertyAddress,
            latitude:
              this.losObject.property_address_lat || this.subjectPropertyLat,
            longitude:
              this.losObject.property_address_long || this.subjectPropertyLng,
            AVMValue: this.avm_amount,
            assessmentValue: this.assessment_value,
          };
          this.losObject.createdAt = this?.losObject?.created_at.toDate();

          const aiAnalyzeData = await firebaseDB
            .firestore()
            .collection("loans")
            .doc(this.$props.id)
            .collection("aiAnalysis")
            .doc(this.losObject.aiAnalysisId)
            .get();
          this.aiAnalyzeData = { ...aiAnalyzeData.data() };
          console.log("this.aiAnalyzeData----", this.aiAnalyzeData);
          // await this.mapfields();
          // await this.objectsToStrings();
          await this.calculatorFields();
          await this.salesCompsProperties();
          // this.streetViewMap(this.propertyAddress);
        }
        this.loading = false;
        console.log(
          "fetchLosDetail complete. Final loading state:",
          this.loading
        );
      } catch (error) {
        console.error("Error in fetchLosDetail:", error);
        this.loading = false;
      }
    },

    updatePropertyClosingCost: function (newValue) {
      this.myValue = newValue;
    },

    formatStrCurrency: function (str) {
      str = str.replace(/[,$]/g, "");
      let num = parseFloat(str);

      let formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      });

      let val = formatter.format(num);
      return val;
    },

    formatCurrency: function (num) {
      if (num == null || typeof num === "undefined") {
        return 0;
      }
      let formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      });

      let val = formatter.format(num);
      return val;
    },

    format$Currency: function (num) {
      if (num == null || typeof num === "undefined") {
        return "";
      }
      const roundedValue = Math.ceil(num);

      return roundedValue.toLocaleString("en-US");
    },

    formatKCurrency: function (num) {
      num = parseFloat(num);
      if (num >= 1000) {
        return "$" + (num / 1000).toFixed(0) + "k";
      } else {
        return "$" + num.toFixed(0);
      }
    },

    formatCurrencyProcessing: function (num) {
      let formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      });

      let val = formatter.format(num);
      return val;
    },

    async mapfields() {
      var result = Object.entries(this.losObject);

      const sortedLosDetailsFieldMap = this.losDetailsFieldMap.sort(
        (a, b) => a.order - b.order
      );

      await sortedLosDetailsFieldMap.map((losmapobj) => {
        result.forEach((el) => {
          if (el[0] === losmapobj.field) {
            el[0] = losmapobj.value;
          }
        });
      });

      const orderedResult = {};
      sortedLosDetailsFieldMap.forEach((losmapobj) => {
        const key = losmapobj.value;
        const entry = result.find(([e1]) => e1 === key) || [null, null];
        const value = entry[1];
        orderedResult[key] = value;
      });

      this.mappedLosObj = orderedResult;
    },
    animateProgressBar() {
      const updateProgress = () => {
        if (!this.progressComplete) {
          this.progress += 5;
        }
        if (this.progressComplete) {
          this.progress = 100;
          this.progressComplete = false;
          clearInterval(progressInterval);
        }
        if (this.progress >= 100) {
          this.progress = 100;
          this.progressComplete = false;
          clearInterval(progressInterval);
        }
      };
      const progressInterval = setInterval(updateProgress, 500);
    },
    async generateLoanReport() {
      this.loadingBar = true;
      this.progress = 0;
      this.animateProgressBar();
      let reportId = this.source == "ma" ? this.mortgageLoanId : this.id;
      let pdfData = {
        id: reportId,
        report: "loan",
      };

      const url =
        location.hostname === "localhost"
          ? `http://localhost:5000`
          : `https://hermosa-server-dot-crebrid-os.uc.r.appspot.com`;

      this.disableDownload = true;
      axios
        .post(`${url}/api/v1/pdf/download-pdf`, {
          losObject: pdfData,
        })
        .then((response) => {
          this.progressComplete = true;
          const pdfUrl = response.data.URL;
          this.disableDownload = true;

          const a = document.createElement("a");
          a.href = pdfUrl;
          a.download = `LoanReport`;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          this.disableDownload = false;
          this.loadingBar = false;
          this.progressComplete = false;
        })
        .catch((err) => {
          this.alertMessage = "Something went wrong, please try again later!";
          this.alertType = "error";
          this.alertState = true;
          console.log("Error: ", err);
          this.loadingBar = false;
        });
    },

    async generateReport() {
      this.loadingBar = true;
      this.progress = 0;
      this.animateProgressBar();
      let reportId = this.source == "ma" ? this.mortgageLoanId : this.id;
      let pdfData = {
        id: reportId,
      };

      const url =
        location.hostname === "localhost"
          ? `http://localhost:5000`
          : `https://hermosa-server-dot-crebrid-os.uc.r.appspot.com`;
      this.disableDownload = true;
      axios
        .post(`${url}/api/v1/pdf/download-pdf`, {
          losObject: pdfData,
        })
        .then((response) => {
          const pdfUrl = response.data.URL;
          this.disableDownload = true;

          const a = document.createElement("a");
          a.href = pdfUrl;
          a.download = `PreApprovalReport`;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          this.disableDownload = false;
          this.loadingBar = false;
          this.progressComplete = false;
        })
        .catch((err) => {
          this.alertMessage = "Something went wrong, please try again later!";
          this.alertType = "error";
          this.alertState = true;
          console.log("Error: ", err);
          this.loading = false;
        });
    },

    objectsToStrings() {
      if (this.attomDataObject?.salesComparables) {
        try {
          this.salesComparables = JSON.stringify(
            this.attomDataObject.salesComparables,
            null,
            2
          );
          console.log("Successfully converted salesComparables");
        } catch (error) {
          console.error("Error converting salesComparables:", error);
        }
      } else {
        console.log("No salesComparables data available to convert");
      }
    },

    calculatorFields() {
      this.purchasePrice = this.losObject?.purchase_price;
      this.rehabBudget = this.losObject?.rehab_budget;
      this.ARV = this.losObject?.investor_arv;
      if (this.attomDataObject.attom_avm_detail) {
        this.avmProperty = this.attomDataObject.attom_avm_detail.property;
      }
      this.loanToCost =
        this.losObject && this.losObject?.ltc_percentage
          ? this.losObject.ltc_percentage
          : 0;
      this.loanToValue =
        this.losObject && this.losObject?.ltv_percentage
          ? this.losObject?.ltv_percentage
          : 0;
    },
    salesCompsProperties() {
      // this.losObject =
      //   this.runsData && this.runsData.keyStoneResult
      //     ? this.runsData.keyStoneResult
      //     : null;
      this.totalBedrooms =
        this.losObject && this.losObject.total_bed_rooms
          ? this.losObject.total_bed_rooms
          : this.attomDataObject?.subject_property_avm_detail?.property[0]
              ?.building?.rooms?.beds
          ? this.attomDataObject?.subject_property_avm_detail?.property[0]
              ?.building?.rooms?.beds
          : 0;
      this.totalBathrooms =
        this.losObject && this.losObject.total_bath_rooms
          ? this.losObject.total_bath_rooms
          : this.attomDataObject?.subject_property_avm_detail?.property[0]
              ?.building?.rooms?.bathstotal
          ? this.attomDataObject?.subject_property_avm_detail?.property[0]
              ?.building?.rooms?.bathstotal
          : 0;
      this.propertySize =
        this.losObject && this.losObject.property_size
          ? this.losObject.property_size
          : this.attomDataObject?.subject_property_avm_detail?.property[0]
              ?.building?.size?.livingsize
          ? this.attomDataObject?.subject_property_avm_detail?.property[0]
              ?.building?.size?.livingsize
          : 0;
      this.Lot =
        this.losObject && this.losObject.lot
          ? this.losObject.lot
          : this.attomDataObject?.subject_property_avm_detail?.property[0]
              ?.building?.size?.grosssize
          ? this.attomDataObject?.subject_property_avm_detail?.property[0]
              ?.building?.size?.grosssize
          : 0;
      this.avmAmount =
        this.losObject && this.losObject.avm_amount
          ? this.losObject.avm_amount
          : 0;
      this.avmAmountLow =
        this.losObject && this.losObject.avm_amount_low
          ? this.losObject.avm_amount_low
          : 0;
      this.avmAmountHigh =
        this.losObject && this.losObject.avm_amount_high
          ? this.losObject.avm_amount_high
          : 0;

      this.assessmentValue =
        this.losObject && this.losObject.assessment_value
          ? this.losObject.assessment_value
          : 0;
      this.assessmentValuePSF =
        this.losObject && this.losObject.assessment_value_psf
          ? this.losObject.assessment_value_psf
          : 0;
      this.avmPsfValue =
        this.losObject && this.losObject.avm_psf_value
          ? Math.round(this.losObject.avm_psf_value)
          : 0;
      // this.avmSnapShotMap(
      //   this.avmSnapShotDetails,
      //   this.subjectProperty,
      //   this.avmAssessmentSnapShot
      // );
    },

    clicked() {
      this.editMode = false;
    },
    calculatePercentDiff(value1, value2) {
      if (!value1 || !value2) return "0";
      const diff = ((value1 - value2) / value2) * 100;
      return diff.toFixed(1);
    },
  },
};
</script>

<style scoped>
::v-deep(.custom-highlight) {
  background-color: rgb(126, 126, 122) !important;
}
</style>

<style lang="scss" scoped>
.full-height {
  min-height: 100vh;
}
.v-text-field .v-label {
  overflow: visible !important;
  top: 1% !important;
}

.label {
  color: #c7c7c7 !important;
}

.item {
  cursor: pointer;
}
.fa {
  margin: 3px;
  font-size: 1rem;
}

.v-tab {
  text-transform: none !important;
}

.d-flex {
  display: flex;
  justify-content: space-between;

  .round {
    width: 20px;
    height: 20px;

    border-radius: 50%;
  }
}
.d-flex-2 {
  display: flex;
  justify-content: space-between;
}
.tab-nav-btn {
  background: #212121;
  width: 100%;
  max-width: 300px;
  padding: 0 16px;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;

  a {
    text-decoration: none;
    color: #69f0ae;
  }
}

#mapDiv {
  min-height: 500px;
  width: 100%;
}

#avm_ss_map_div {
  min-height: 500px;
  width: 100%;
}

#avm_details_map_div {
  min-height: 500px;
  width: 100%;
}

#pano {
  min-height: 500px;
  width: 100%;
}
</style>
