<template>
  <div class="full-height pa-10" style="margin-left: 56px">
    <div v-if="loading">
      <loader />
    </div>

    <v-snackbar v-model="snackbar" :timeout="snackBarTimeout" :color="color">{{
      snackBarText
    }}</v-snackbar>

    <div class="mx-auto rounded-0 full-height">
      <!-- TMA Content -->
      <v-sheet class="pa-4" color="grey darken-3" data-app>
        <v-row class="align-center justify-center">
          <v-col>
            <v-text-field
              class="pa-2 my-custom-search"
              v-model="search"
              label="Search Loan Applications..."
              flat
              solo-inverted
              hide-details
              clearable
              clear-icon="mdi-close-circle-outline"
            ></v-text-field>
          </v-col>
          <v-col cols="auto">
            <v-btn large outlined @click="openDialog">Add Loan</v-btn>
          </v-col>
        </v-row>
      </v-sheet>
      <div>
        <v-data-table
          :headers="loanHeaders"
          :items="los"
          :items-per-page="50"
          item-key="id"
          :search="search"
          sort-by="createdAt"
          :sort-desc="true"
          class="elevation-1"
        >
          <template v-slot:item.created_at="{ item }">
            <div v-if="item.created_at">
              {{
                item.created_at
                  .toDate()
                  .toLocaleString("en-US", {
                    year: "2-digit",
                    month: "numeric",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    hour12: true,
                  })
                  .replace(/\//g, "-")
              }}
            </div>
          </template>
          <template v-slot:item.loan_status="{ item }">
            <div v-if="item.loan_Status">TBD</div>
          </template>
          <template v-slot:item.is_process_application="{ item }">
            <div>
              {{
                item.is_process_application
                  ? item.is_process_application
                  : false
              }}
            </div>
          </template>
          <template v-slot:item.actions="{ item }">
            <router-link
              :to="{
                path: `/los/loans/${item.id}`,
                query: { loan_id: item.loan_id, source: 'web' },
              }"
            >
              <v-btn x-small outlined color="green accent-2">
                View Details
              </v-btn>
            </router-link>
          </template>
        </v-data-table>
      </div>
    </div>

    <v-dialog v-model="dialog" max-width="600px">
      <v-card>
        <v-card-title>Add Loan Details</v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid">
            <vue-google-autocomplete
              ref="address"
              id="autocomplete"
              classname="form-control"
              class="autocomplete-address"
              placeholder="Enter Address"
              v-model="formData.address"
              :options="autocompleteOptions"
              @placechanged="getAddressData"
              outlined
            />
            <v-text-field
              v-model="formData.purchase_price"
              label="Purchase Price"
              :rules="[(v) => !!v || 'Purchase price is required']"
              required
              outlined
              type="number"
              clearable
            ></v-text-field>
            <v-text-field
              v-model="formData.rehab_budget"
              label="Rehab Budget"
              :rules="[(v) => !!v || 'Rehab budget is required']"
              required
              outlined
              type="number"
              clearable
            ></v-text-field>
            <v-text-field
              v-model="formData.investor_arv"
              label="ARV"
              :rules="[(v) => !!v || 'ARV is required']"
              required
              outlined
              type="number"
              clearable
            ></v-text-field>
            <v-select
              v-model="formData.credit_score"
              :items="creditScoreOptions"
              label="What is your estimated credit score?"
              :rules="[(v) => !!v || 'Credit score is required']"
              required
              outlined
            ></v-select>
            <v-select
              v-model="formData.renovation_projects"
              :items="fixAndFlipOptions"
              label="How many previous fix & flips have you done?"
              :rules="[(v) => !!v || 'Experience is required']"
              required
              outlined
            ></v-select>
            <v-select
              v-model="formData.property_condition"
              :items="propertyConditionOptions"
              label="Property Condition"
              :rules="[(v) => !!v || 'Property condition is required']"
              required
              outlined
            ></v-select>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeDialog">Cancel</v-btn>
          <v-btn color="primary" :disabled="!valid" @click="submitForm"
            >Submit</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import Loader from "../../../components/Loader.vue";
import { firebaseappDB, firebaseDB } from "../../../auth/firebase-auth";
import VueGoogleAutocomplete from "vue-google-autocomplete";

export default {
  template: "#app-template",
  name: "loans",
  components: {
    Loader,
    VueGoogleAutocomplete,
  },
  data() {
    return {
      loanData: [],
      applicationData: {},
      dialog: false,
      notifications: false,
      sound: true,
      widgets: false,
      loading: true,
      color: "success",
      snackbar: false,
      snackBarText: "",
      tab: "",
      snackBarTimeout: 2000,
      search: "",
      los: [],
      valid: false,
      user: null,
      formData: {
        address: "",
        purchase_price: null,
        rehab_budget: null,
        investor_arv: null,
        credit_score: null,
        renovation_projects: null,
        property_condition: null,
        platform: "mobile",
        source: "app",
      },
      creditScoreOptions: ["Under 700", "700-741", "Over 741"],
      fixAndFlipOptions: [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11+",
      ],
      propertyConditionOptions: [
        "Uninhabitable",
        "Significant Repairs",
        "Worn Adequate",
        "Well Maintained",
        "Excellent",
        "Nearly New",
      ],
      autocompleteOptions: {
        types: ["address"],
        componentRestrictions: { country: "us" },
      },
      loanHeaders: [
        {
          text: "Email",
          align: "start",
          width: "100",
          value: "email",
        },
        // {
        //   text: "Reference #",
        //   align: "start",
        //   width: "50",
        //   value: "ReferenceNumber",
        // },
        {
          text: "Address",
          align: "start",
          width: "100",
          value: "street",
        },
        {
          text: "City",
          align: "start",
          width: "50",
          value: "city",
        },
        {
          text: "State",
          align: "start",
          width: "50",
          value: "state",
        },
        // {
        //   text: "Zipcode",
        //   align: "start",
        //   width: "50",
        //   value: "P1005",
        // },
        {
          text: "Created At",
          align: "start",
          width: "100",
          value: "created_at",
        },
        {
          text: "Processed",
          align: "center",
          width: "100",
          value: "is_process_application",
        },
        {
          text: "Actions",
          align: "center",
          width: "100",
          value: "actions",
        },
      ],
    };
  },
  mounted() {
    this.loading = true;
    let start = Date.now();
    console.log("Started : ", start);
    this.user = this.$cookies.get("user");
    console.log(this.user);
    this.fetchLoanData();
  },
  methods: {
    async fetchLoanData() {
      const losRef = firebaseDB
        .firestore()
        .collection("projects")
        .orderBy("created_at", "desc")
        .where("source", "==", "web");
      try {
        const { docs } = await losRef.get();
        this.los = docs.map((doc) => {
          const { id } = doc;
          const data = doc.data();
          return { id, ...data };
        });
        this.loading = false;
      } catch (error) {
        console.log("Error in fetching los data:\n" + error);
        this.loading = false;
      }
    },
    openDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
    resetForm() {
      this.formData = {
        address: "",
        purchase_price: null,
        rehab_budget: null,
        investor_arv: null,
      };
    },
    getAddressData(place) {
      if (place) {
        this.formData.street =
          place?.street_number && place?.route
            ? place?.street_number + " " + place?.route
            : place?.street_number
            ? place?.street_number
            : place?.route
            ? place?.route
            : "";
        this.formData.city = place?.locality ?? "";
        this.formData.state = place?.administrative_area_level_1 ?? "";
        this.formData.zip = place?.postal_code ?? "";
        this.formData.address = place.formatted_address;
      }
    },
    async submitForm() {
      if (this.$refs.form.validate()) {
        try {
          this.formData.email = this.user?.email;
          this.formData.user_id = this.user?.uid;
          this.formData.first_name = this.user?.displayName.split(" ")[0];
          this.formData.last_name = this.user?.displayName.split(" ")[1];
          this.formData.platform = "web";
          this.formData.source = "web";
          console.log("formdata--------------", this.formData);
          this.closeDialog();
          this.loading = true;
          const token = `Bearer eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJtZXNzYWdlIjoiVG9rZW4gZm9yIGNyZWJyaWQtb3MgQVBJIiwiaWF0IjoxNjc1MzQxOTA1fQ._MKnNlPgvURchmlE1YuewP_FEfJ7rJMmVnsiR0FyfPJ_1BQAdPojeOvfwTPaegyqMFYXQN21ytPMl24Zky589A`;
          const url =
            "https://us-central1-crebrid-os.cloudfunctions.net/crebridApp/api/v1/create-loan";
          const headers = {
            "Content-Type": "application/json",
            Authorization: token,
          };
          const response = await axios.post(
            url,
            { ...this.formData },
            { headers }
          );
          console.log("Loan added successfully:", response.data);
          const loanId = response.data.loan_id;
          this.loading = false;
          this.$router.push(`/los/loans/${loanId}`);
          this.resetForm();
        } catch (error) {
          console.error("Error adding loan:", error);
        }
      }
    },
  },
};
</script>

<style scoped>
.my-custom-search {
  width: 15 00px;
  font-size: 16px;
}

.autocomplete-address {
  width: 100%;
  height: 50px;
  border: 2px solid #1976d2;
  padding: 0 16px;
  border-radius: 4px;
  color: white;
  margin-bottom: 20px;
}
</style>
